import React from 'react'
import { Switch, Route } from "react-router-dom"
import NewTransaction from './new-transaction/new-transaction.page'
import Transactions from './transactions.page'

const TransactionController = () => {
    return (
        <Switch>
            <Route path="/transactions/create" component={NewTransaction} />
            <Route path="/transactions/all" component={Transactions} />
        </Switch>
    )
}

export default TransactionController