import axios from 'axios'
import { API_ENDPOINT } from '../../config'

const getDefaultState = () => {
    return {
        loading: false,
        metrics: {
            wallet: {
                credit: 0,
                debit: 0,
                balance: 0
            }
        }
    }
}

const load = (instance) => {
    instance.setState({
        ...instance.state,
        loading: true
    })
    axios({
        method: 'get',
        url: API_ENDPOINT + "/metrics/self",
        headers: {
            "Authorization": instance.props.auth.authorization
        }
    }).then(response => handleLoadResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loading: false
            })
            alert("Unknown Error")
        })
}

const handleLoadResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                metrics: response.data.data
            })
            break
        default:
            instance.setState({
                ...getDefaultState(),
                loading: false
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        load: () => load(instance)
    }
}

export default Service