import React from 'react'
import { withRouter } from 'react-router'

const Success = props => {
    return (
        <div>
            <div className="card" style={{maxWidth: "500px"}}>
                <div className={"flag success"}>
                    Your payment was successful
                </div>
                <br/>
                <div className="actions">
                <button type="submit" onClick={() => props.history.push('/transactions/all')}>Return</button>
            </div>
            </div>
        </div>
    )
}

export default withRouter(Success)