import React from 'react'
import NumberFormat from 'react-number-format'
import './form.styles.scss'

const Form = (props) => {
    return (
        <form>
            <p>Equivalent Eastcoins: <NumberFormat value={props.eastcoins} displayType={'text'} thousandSeparator={true} renderText={value => value} /></p>
            <div className="inputs">
                <div className="row" id="amount">
                    <div className="form-label">
                        <label>Amount ($)</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="amount" value={props.amount} onChange={props.onChange} placeholder="min 10"/>
                        <span className="error">{props.errors.amount}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="submit" onClick={props.onSubmit}>Pay with Paypal</button>
            </div>
        </form>
    )
}

export default Form