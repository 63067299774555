import React from 'react'
import { connect } from 'react-redux'
import './dashboard.styles.scss'
import imageDashbord from '../../assets/icons/dashboard.svg'
import imageOrders from '../../assets/icons/payer.svg'
import imageSecurity from '../../assets/icons/security.svg'
import imageSettings from '../../assets/icons/config.svg'
import imageTelegram from '../../assets/icons/telegram.svg'
import { Link, withRouter } from 'react-router-dom'
import Service from './dashboard.service'

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.load()
    }

    render() {
        return (
            <div className="dashboard">
                <h3>Dashboard</h3>
                <div className="downloads">
                    <a rel="noopener noreferrer" target="_blank" href="https://eastcoin.io/downloads/eastcoin-onepaper.pdf">Pitch Deck</a>
                    <Link to="/transactions/create">Buy Eurufly Share</Link>
                    <a rel="noopener noreferrer" target="_blank" href="https://eastcoin.io/downloads/eastcoin-whitepaper.pdf">Founders Club</a>
                </div>
                <div className="card">
                    <h2>Congratulations on Join Ecfunder Community first round equity sale -Pathway of Ownership.</h2>
                    <h4>Investing your minimum of $10 will create a ripple effect, enabling users to book luxury flights, pay with Eurufly Pay, and exchange loyalty points, thereby acquiring a piece of the Eurufly $10M valuation. 

$1 = 1 Eurufly Share</h4>
                    <br /><br /><br />
                    <h2>How To Buy Token!</h2>
                    <br />
                    <p><strong>Step 1:</strong> Click buy Eurufly Share on the dashboard.</p>
                    <p><strong>Step 2:</strong> Enter the desired number of shares (minimum 10).</p>
                    <p><strong>Step 3:</strong> Accepted Currency FIAT, PayPal, USD, Zelle.</p>
                    <p><strong>Step 4:</strong> Use your referral link to invite friends and earn a 2% commission.</p>
                    <p><strong>Step 5:</strong> Share transferred to your Dashboard.</p>

                    <p className="telegram">Join the conversation on Telegram. <a rel="noopener noreferrer" target="_blank" href="https://t.me/ecfunder"><img alt="telegram" src={imageTelegram} /></a></p>
                </div>
                <br />
                <div className="cards">
                    <div className="card membership" onClick={() => this.props.history.push("/transactions/all")}>
                        <div>
                            <img alt="" src={imageDashbord} />
                        </div>
                        <div>
                            <strong>Account Balance</strong>
                            <span>{this.state.metrics.wallet.balance}</span>
                        </div>
                    </div>
                    <div className="card applications" onClick={() => this.props.history.push("/transactions/all")}>
                        <div>
                            <img alt="" src={imageOrders} />
                        </div>
                        <div>
                            <strong>Transactions</strong>
                            <span>view</span>
                        </div>
                    </div>
                    <div className="card program" onClick={() => this.props.history.push("/settings/profile")}>
                        <div>
                            <img alt="" src={imageSettings} />
                        </div>
                        <div>
                            <strong>Profile</strong>
                            <span>update</span>
                        </div>
                    </div>
                    <div className="card points" onClick={() => this.props.history.push("/settings/change-password")}>
                        <div>
                            <img alt="" src={imageSecurity} />
                        </div>
                        <div>
                            <strong>Account</strong>
                            <span>change password</span>
                        </div>
                    </div>
                    <div className="card membership" onClick={() => this.props.history.push("/referrals/all")}>
                        <div>
                            <img alt="" src={imageDashbord} />
                        </div>
                        <div>
                            <strong>Referrals</strong>
                            <span>{this.state.metrics.referrals}</span>
                        </div>
                    </div>
                    <div className="card applications">
                        <div>
                            <img alt="" src={imageOrders} />
                        </div>
                        <div>
                            <strong>My Referral Link</strong>
                            <a target="_blank" rel="noopener noreferrer" href={"https://wallet.eastcoin.io/auth/register/" + this.props.auth.referralCode}>https://wallet.eastcoin.io/auth/register/{this.props.auth.referralCode}</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Dashboard))