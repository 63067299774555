import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import store from './redux/stores/store'
import { Provider } from 'react-redux'
import './app.styles.scss'
import DashboardPage from './pages/dashboard/dashboard.page'
import RequireLogin from './components/require-login/require-login.component'
import DefaultTemplate from './templates/default/default.template'
import AuthTemplate from './templates/auth/auth.template'
import AuthController from './pages/auth/auth.controller'
import TransactionController from './pages/transactions/transaction.controller'
import SettingsController from './pages/settings/settings.controller'
import { PAYPAL_JS_URL } from './config'
import {AppendScript} from './utils/UIHelper'
import ReferralController from './pages/referrals/referral.controller'

class AppController extends React.Component {

    componentDidMount() {
        AppendScript(PAYPAL_JS_URL)
    }

    render() {
        return (
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route path="/auth" component={() => <AuthTemplate><AuthController /></AuthTemplate>} />
                        <Route path="/transactions" component={() => <RequireLogin><DefaultTemplate><TransactionController /></DefaultTemplate></RequireLogin>} />
                        <Route path="/settings" component={() => <RequireLogin><DefaultTemplate><SettingsController /></DefaultTemplate></RequireLogin>} />
                        <Route path="/referrals" component={() => <RequireLogin><DefaultTemplate><ReferralController /></DefaultTemplate></RequireLogin>} />
                        <Route path="/" component={() => <RequireLogin><DefaultTemplate><DashboardPage /></DefaultTemplate></RequireLogin>} />
                    </Switch>
                </Router>
            </Provider>
        )
    }
}

export default AppController