import React from 'react'
import './auth.styles.scss'
import '../form.styles.scss'
import { SITE_NAME } from '../../config'

class AuthTemplate extends React.Component {


    render() {
        return (
            <section className="auth">
                <div>
                    <div className="container">
                        <header>
                            <h1>Welcome to Ecfunder, where you can secure your future through investment. </h1>
                        </header>
                        {this.props.children}
                        <footer>
                            <p>© 2024 {SITE_NAME}. All rights reserved.</p>
                        </footer>
                    </div>
                </div>
                <div>
                    <div>
                        
                    </div>
                </div>
            </section>
        )
    }
}

export default AuthTemplate