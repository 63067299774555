import React, { useEffect, useRef } from 'react'
import './paypal.styles.scss'

const Paypal = props => {
    const options = props
    const paypal = useRef()

    useEffect(() => {
        window.paypal.Buttons({
            createOrder: function (data, actions) {
                return actions.order.create({
                    intent: "capture",
                    purchase_units: [{
                        description: "Purchase of Eastcoin",
                        amount: {
                            value: options.amount
                        }
                    }]
                })
            },
            onApprove: options.onApprove
        }).render(paypal.current)
    })

    return (
        <div className="card paypal">
            <div ref={paypal}></div>
        </div>
    )
}

export default Paypal