import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Service from './new-transaction.service'
import './new-transaction.styles.scss'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import Form from './form/form.component'
import Paypal from '../../../components/paypal/paypal.component'
import Success from './components/success/success.component'
import imageBtc from '../../../assets/images/btc.png'
import imageEth from '../../../assets/images/eth.png'
import imageUdt from '../../../assets/images/udt.png'

class NewTransaction extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onChange = e => {
        let amount = e.target.value
        this.setState({
            ...this.state,
            amount: amount,
            eastcoins: amount >= 10 ? (amount / this.state.eastcoinPerDollar).toFixed(2) : 0
        })
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.service.isValid() && this.service.save()
    }

    getPaypalInfo = () => {
        return {
            amount: this.state.amount,
            currency: "USD",
        }
    }

    onPaypalApprove = (data, actions) => {
        this.service.paypalSuccess(data)
    }

    getComponent = () => {
        switch (this.state.tab) {
            case 1:
                return <Paypal onApprove={this.onPaypalApprove} {...this.getPaypalInfo()} />
            case 2:
                return <Success />
            default:
                return <>
                    <div className="card">
                        <div className={"flag " + this.state.flag.type}>
                            {this.state.flag.text}
                        </div>
                        <Form onSubmit={this.onSubmit} {...this.state} onChange={this.onChange} />
                    </div>
                    <br />
                    <div className="header">
                        <h4>Other Payment Methods</h4>
                    </div>
                    <br />
                    <div className="card">
                        <div className="payment">
                            <div>
                                <img alt="" src={imageBtc} />
                                <div>
                                    <h4>BTC Address</h4>
                                    <p>1Q254xqNgtQu3K7HQBs4mmTvTY8s1sena7</p>
                                    <br /><br />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h4>Smart Contract Address</h4>
                                    <p>0x6631f3aed42f1ad62e13bc9e2cda44b855c4e2c6</p>
                                    <br /><br />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h4>USDT (BSC) Address</h4>
                                    <p>0xC9fB9F3a5EFBEfBaEAF8CB83616392334356bd9E</p>
                                    <br /><br />
                                </div>
                            </div>
                        </div>
                        <p>Send Evidence of payment with the registration email to tokensale@eastcoin.io</p>
                    </div>
                </>
        }
    }

    render() {
        return (
            <div className="create-transaction">
                {this.state.saving && <Dialog><Loading /></Dialog>}
                <div className="header">
                    <h4>Buy East</h4>
                </div>
                <br />
                {this.getComponent()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(NewTransaction))